/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    img: "img"
  }, _provideComponents(), props.components), {HrefLangManager, ButtonCta} = _components;
  if (!ButtonCta) _missingMdxReference("ButtonCta", true);
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/resources/hearing-loss/why-you-need-hearing-test/'], ['en', 'https://www.hear.com/resources/hearing-loss/why-you-need-hearing-test/'], ['en-US', 'https://www.hear.com/resources/hearing-loss/why-you-need-hearing-test/'], ['en-CA', 'https://ca.hear.com/resources/hearing-loss/why-you-need-hearing-test/']]
  }), "\n", React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "why-do-you-need-a-hearing-test",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#why-do-you-need-a-hearing-test",
    "aria-label": "why do you need a hearing test permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Why Do You Need A Hearing Test?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Contrary to popular belief, hearing loss affects us earlier than we think. The National Institutes of Health reports that 49% of people experience hearing loss by age 39. That percentage increases to 81% by the age of 59. So, if you’re over 40, there’s a good chance you have hearing loss. It’s a slow, gradual process which is why it often goes unnoticed."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Many of our customers already know they have hearing loss, so they often ask us, “Why do I need a hearing test?” A hearing test administered by a licensed professional provides the details of your hearing ability at different pitches or frequencies. The results are plotted on a chart called an audiogram, which helps to visualize your hearing ability. In addition, it acts as a prescription. An audiologist or hearing aid specialist uses it to customize your hearing aids to your specific needs."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "your-hearing-loss-is-unique",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#your-hearing-loss-is-unique",
    "aria-label": "your hearing loss is unique permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Your hearing loss is unique"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Everyone’s hearing ability is different. Just like a tailor customizes a suit to fit your specific height and weight, an audiologist will program your hearing aids to match your specific hearing loss. For example, below is an audiogram illustrating high-frequency hearing loss."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1660545349/assets/na/seo-website/Pure-Tone-Audiogram.png",
    alt: "Pure Tone Audiogram Illustration",
    className: "c-md-img"
  })), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Anything above twenty decibels (volume) is considered normal hearing. But at 2000 hertz (frequency) this person starts to experience hearing loss and their hearing ability continues to decline at the higher frequencies. With severe, high-frequency hearing loss, they will have difficulty understanding speech and following a conversation, especially in noisier environments."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "This is because many of the consonant sounds like K, F, S, and Th are above 3000 hertz. Hence, they affect the way we understand speech. For example, did she say “What time is it” or “What kind is it”?"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "the-speech-banana",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-speech-banana",
    "aria-label": "the speech banana permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The speech banana"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "To further illustrate this concept, below is an audiogram that includes the sounds of speech. When you highlight the letters in yellow, you can see why hearing care professionals call it “the speech banana”."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1660545349/assets/na/seo-website/the-speech-banana.png",
    alt: "The Speech Banana Audiogram Illustration",
    className: "c-md-img"
  })), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "An audiologist or hearing aid specialist uses the information from an audiogram to fine-tune your devices. They can shape the specific frequencies and decibels to get as close as possible to normal hearing. (See green arrows on audiogram below.)"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1660545349/assets/na/seo-website/audiogram-with-arrows.png",
    alt: "Pure Tone Audiogram with Arrows Illustration",
    className: "c-md-img"
  })), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Therefore, the main reason to test your hearing to ensure your hearing aids can be programmed by a licensed professional to meet your exact needs."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "were-here-to-help",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#were-here-to-help",
    "aria-label": "were here to help permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "We’re here to help"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you have any questions about hearing tests or audiograms, give us a call at (866) 585-8861. We’ll be happy to share more information and answer any questions you might have. And if you haven’t signed up for our 30-day trial yet, you can do so now by clicking the button below."), "\n", React.createElement(ButtonCta, {
    copy: "Sign up for a 30-day trial",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
